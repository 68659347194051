<template>
  <div class="input-chip">
    <div class="input-chip__container" :class="{ 'input-chip__error': errorMessage }">
      <v-chip-group v-if="value.length" column>
        <v-chip
          v-for="(chip, i) in value"
          close
          small
          color="blue"
          text-color="white"
          :key="i"
          :ripple="false"
          @click:close="removeChip(i)"
          @click="editChip(i)"
        >
          {{ chip }}
        </v-chip>
      </v-chip-group>
      <v-text-field
        ref="textField"
        v-model="textField"
        :appendIcon="appendIcon"
        dense
        flat
        hide-details
        :placeholder="placeholder"
        :readonly="readonly"
        solo
        @blur="blurHandler"
        @change="$emit('change', $event)"
        @keyup="textFieldKey"
        @keypress="keypressHandler"
        @paste="pasteHandler"
      ></v-text-field>
    </div>
    <div v-if="!hideDetails" class="input-chip__details v-text-field__details">
      <div class="input-chip__messages v-messages" :class="{ 'input-chip__error': errorMessage }">
        {{ errorMessage || messages }}
      </div>
      <div v-if="counter" class="input-chip__counter v-counter">{{ computedCounter }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      errorMessage: '',
      textField: '',
    }
  },
  created: function () {
    // definde max-length inicial após <input /> ser renderizado
    setTimeout(this.setMaxLength, 200);
  },
  computed: {
    computedCounter: function () {
      if (!this.counter) {
        return '';
      }

      if (typeof this.counter === 'number') {
        return `${this.length} / ${this.counter}`;
      }

      return this.length;
    },
    length: function () {
      const allTerms = this.value.join('; ');
      // Compensar o '; ' que é inserido para cada termo novo
      const separator = this.value.length > 2 ? 2 : 0;
      return this.textField.length + allTerms.length + separator;
    },
    textFieldMaxLenth: function () {
      if (!this.maxLength || this.maxLength < 0) {
        return -1;
      }

      // Compensar o '; ' que é inserido para cada termo novo
      const separator = this.textField.length ? 2 : 0;
      const length = this.maxLength - this.length + this.textField.length - separator;
      return length < 0 ? 0 : length;
    },
  },
  methods: {
    addFilterTerm: function (term) {
      const termsLength = this.value.join('; ').length;

      if (!term || (this.maxLength && (termsLength + term.length) > this.maxLength)) {
        return;
      }

      this.value.push(term);
      this.value.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      this.textField = '';
      this.$emit('input', this.value);
    },
    /**
     * @param {FocusEvent} focusEvent evento de foco disparado pelo elemento input
     */
    blurHandler: function (event) {
      const { value } = event.target;
      if (value) {
        this.addFilterTerm(value);
      }

      this.validate();
      this.$emit('blur', event);
    },
    editChip: function (index) {
      const chip = this.value.splice(index, 1);
      const textField = this.textField;
      this.textField = chip;

      if (textField) {
        this.addFilterTerm(textField);
      }

      this.$refs.textField.focus();
    },
    keypressHandler: function (event) {
      const { altKey, charCode, code, ctrlKey, key, keyCode, shiftKey } = event;
      this.$emit('keypress', { target: this, altKey, charCode, code, ctrlKey, key, keyCode, shiftKey });
    },
    pasteHandler: function (event) {
      event.preventDefault();
      const pasteData = event.clipboardData.getData('text/plain') || '';
      const terms = pasteData.split(';') || [];
      terms.forEach((term) => {
        const parsed = term.trim();
        this.addFilterTerm(parsed);
      });
      this.$emit('paste', { target: this, pasteData });
    },
    focus: function () {
      this.$refs.textField.focus();
    },
    removeChip: function (index) {
      this.value.splice(index, 1);
      this.$emit('input', this.value);
      this.focus();
    },
    setMaxLength: function () {
      const textField = this.$refs.textField?.$el?.querySelector('input');
      if (textField) {
        if (this.textFieldMaxLenth > -1) {
          textField.maxLength = this.textFieldMaxLenth;
        } else {
          textField.removeAttribute('maxLength');
        }
      }
    },
    textFieldKey: function (event) {
      const { code, target } = event;
      if (['Enter', 'NumpadEnter', 'Slash'].includes(code)) {
        let { value } = target;

        if (value) {
          if (value.endsWith(';')) {
            value = value.slice(0, -1);
          }
          
          this.addFilterTerm(value);
        }
      }

      this.$emit('keyup', event);
    },
    validate: function () {
      if (!this.rules) {
        this.errorMessage = '';
        return;
      }

      const rules = Array.isArray(this.rules) ? this.rules : [this.rules];
      rules.some((rule) => {
        const errorMessage = rule(this.value);
        const invalid = typeof errorMessage === 'string';

        if (invalid) {
          this.errorMessage = errorMessage;
        } else {
          this.errorMessage = '';
        }

        return invalid;
      });
    },
  },
  props: {
    appendIcon: String,
    counter: [Boolean, Number],
    hideDetails: Boolean,
    maxLength: Number,
    messages: String,
    placeholder: {
      type: String,
    },
    readonly: Boolean,
    rules: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  watch: {
    textFieldMaxLenth: function () {
      this.setMaxLength();
    },
    value: function () {
      this.validate();
    },
  },
}
</script>

<style lang="scss" scoped>
  .input-chip {
    width: 100%;
  }

  .input-chip__container {
    border-radius: 4px;
    border: thin solid lightgray;
    padding: 2px 6px;
    margin-bottom: 4px;

    &.input-chip__error {
      border-color: #CA0D0D;
    }

    &::v-deep {
      .v-chip-group .v-slide-group__content {
        padding: unset;
      }

      .v-chip-group--column .v-slide-group__content {
        margin-bottom: -4px;
      }
    }
  }

  .input-chip__details {
    color: #00000099;
    padding: 0 12px;
    margin-bottom: 8px;
  }

  .input-chip__messages {
    &.input-chip__error {
      color: #CA0D0D;
    }
  }

  .input-chip__counter {
    margin-left: 8px;
    max-width: 100%;
  }
</style>
